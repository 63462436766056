import React from 'react';
import { Link, TableCell } from '@mui/material';
import { IRowStyle } from '../../types/WorkEntryProps';
import { GridTableRow } from '../GridTableRow';
import { WorkEntryParentRowProps } from './WorkEntryParentRow';

interface MiniScreenRowsProps extends WorkEntryParentRowProps {
    gridRowStyle: IRowStyle;
    tableType: string;
    link: string;
    name: string;
    groupType: string;
    typeOfWork?: string;
    lastWorkedOnDetail?: string;
}
export const MiniScreenRows: React.FC<MiniScreenRowsProps> = ({ gridRowStyle, tableType, link, name, groupType, typeOfWork, lastWorkedOnDetail }) => {
    return (<>
        <GridTableRow sx={{ ...gridRowStyle.aux }}>
            <TableCell>{tableType}</TableCell>
            <TableCell component="th" scope="row">
                <Link href={link}>{name}</Link>
            </TableCell>
        </GridTableRow>
        <GridTableRow sx={{ ...gridRowStyle.aux }}>
            <TableCell>{groupType}</TableCell>
            <TableCell component="th" scope="row">
                {typeOfWork ?? ''}
            </TableCell>
        </GridTableRow>
        <GridTableRow sx={{ ...gridRowStyle.aux }}>
            <TableCell>Last change</TableCell>
            <TableCell component="th" scope="row">
                {lastWorkedOnDetail ?? ''}
            </TableCell>
        </GridTableRow>
    </>);
};
