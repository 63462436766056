import { IRowStyle } from '../types/WorkEntryProps';
import { MediaState } from '../app/store';

export function getGridTemplateColumns(media: MediaState): IRowStyle {
  const defaultDateGrid = '3fr 2fr 5fr';
  const defaultFileGrid = '4fr 4fr 3fr 5em';
  if (media.isMinScreen) return {
    main: { gridTemplateColumns: `25px 2fr 25px 2fr 2fr` },
    date: { gridTemplateColumns: defaultDateGrid },
    file: { gridTemplateColumns: defaultFileGrid } // fole table in collapsible
  };
  if (media.isExtraSmallScreen) return {
    main: { gridTemplateColumns: `50px 1fr 2fr` },
    aux: { gridTemplateColumns: `50px 1fr` },
    date: { gridTemplateColumns: '3fr 5fr' },
    dateAux: { gridTemplateColumns: `1fr` },
    file: { gridTemplateColumns: '50% 50%' },
    fileAux: { gridTemplateColumns: `1fr` }, // fole table in collapsible - cells converted to rows when too narrow
  };
  return {
    main: { gridTemplateColumns: `70px 2fr 25px 2fr 2fr` },
    date: { gridTemplateColumns: defaultDateGrid },
    file: { gridTemplateColumns: defaultFileGrid }
  };
}
