import React from 'react';
import Typography from '@mui/material/Typography';



interface PrintStatusProps {
    status?: string;
}

export const PrintStatus: React.FC<PrintStatusProps> = ({ status }) => {
    return status
        ? (
            <Typography variant="h6" gutterBottom component="div">
                Status: {status}
            </Typography>
        )
        : (<></>);
};
