import React from 'react';
import { TableRow, useMediaQuery } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import CommitRow from './Row/CommitRow';
import { IListedFileProps } from '../../../types/IListOfFiles';
import { IRowStyle } from '../../../types/WorkEntryProps';
import { MediaState } from '../../../app/store';
import { LargeCommitTableHead } from './LargeCommitTableHead';

interface FileTableProps {
    files: Array<IListedFileProps>;
    type: string;
    gridRowStyle: IRowStyle
    media: MediaState
}

export const FileTable: React.FC<FileTableProps> = ({files,type,gridRowStyle, media}) => {

       return (
        <>
            <Typography variant="h6" gutterBottom component="div">{type} files</Typography>

            <Table size="medium" aria-label={type} sx={{width:'calc(min(100vw,100%))'}} >
                <LargeCommitTableHead {...{media, gridRowStyle}}/>
                <TableBody>
                    {files.filter((row: IListedFileProps)=>row.type==='md').map((row: IListedFileProps) => {
                        row.id = row.file + row.description
                        return (
                            <CommitRow gridRowStyle={gridRowStyle} key={row.file} row={row} media={media} />
                        );
                    })}
                </TableBody>

            </Table>

        </>
    );
};

