import React, { useEffect, useRef, useState } from 'react';
import { TableCell, Button, Typography } from '@mui/material';
import { RootState } from '../../../../app/store';
import { IListedFileProps } from '../../../../types/IListOfFiles';
import { connect } from 'react-redux';
import {  revealFile, revealDescription, updateDescriptions, updateFile } from '../../../../ui/spoilersSlice'
import { hide } from 'yargs';

type TSpoilingCell = {
    shouldShow: number | undefined;
    row: IListedFileProps;
    overload?: {file?: JSX.Element, description?: JSX.Element, modified?: Date}
    show: any;
    message?: string;
    buttonTextRevealAll?: string;
    buttonText?: string;
    component?: any;
    scope?: any;
    revealFile: Function;
    revealDescription: Function;
    updateFile: Function;
    updateDescriptions: Function;
    type: 'file' | 'description';
    fileSet: Array<string>;
    descriptionSet: Array<string>;
    hideButton?: boolean

};
const SpoilingCell: React.FC<TSpoilingCell> = ({ shouldShow, row, show, component, scope, message, buttonText, buttonTextRevealAll, type, revealDescription, revealFile, fileSet, descriptionSet, updateFile, updateDescriptions, overload, hideButton }: TSpoilingCell) => {
    const revealRef = useRef<Function>(console.log);
    const updateRef = useRef<Function>(console.log);

    const [set, setSet] = useState<Set<string>>(new Set());
    useEffect(() => {
        console.log('useEfftect');
        console.log(fileSet);
        switch (type) {
            case 'file':
                revealRef.current = revealFile;
                updateRef.current = updateFile;
                setSet(new Set(fileSet));
                break;
            case 'description':
                revealRef.current = revealDescription;
                updateRef.current = updateDescriptions;

                setSet(new Set(descriptionSet));
                break;
            default:
        }
    }, [type, fileSet, descriptionSet, revealRef, revealDescription, revealFile, updateDescriptions, updateFile]);

    const revealThis = () => {
        revealRef.current(`${row.id}`);
        console.log('reveal');
    };
    const renderHidden = () => {
        if (hideButton) return <></>
        return <Typography paragraph><Button onClick={revealThis}>{buttonText}</Button> </Typography>;
    };
    const renderVisibleSpoilersOn = () => {
        console.log('renderVisibleSpoilersOn');
        return <Typography paragraph><Button onClick={(e) => { e.stopPropagation(); show(); }}>{buttonTextRevealAll}</Button></Typography>;
    };
    const render = (condition: boolean | undefined, callback: Function) => {
        // console.log('render called')
        // console.log(condition)
        if (condition) return callback();
        return '';

    };
    let i=0;
    const cell = row[type].split("\n").map(n=>{
            return <Typography key={i} component="div" style={{ all: 'unset', 'display':'block' }}>{n}</Typography>
            i++
        })
    
    return (
        <TableCell {...{ component }} {...{ scope }} {...set.has(`${row.id}`) ? {
            sx: { background: '#e3f1e3' }, onClick: () => {
                console.log('DELETE');

                set.delete(`${row.id}`);
                updateRef.current(set);
            }
        } : { onClick: ()=>{
            if (hideButton) revealThis()
        }}} >
            {shouldShow || set.has(`${row.id}`) ? (overload?.[type] || cell) : hideButton ? message+'\nClick to reveal.': message}

            {render(!set.has(`${row.id}`) && !shouldShow, renderHidden)}
            {render(set.has(`${row.id}`) && !shouldShow, renderVisibleSpoilersOn)}


        </TableCell>
    );
};
// Map Redux state to component props
const mapCellStateToProps = (state: RootState) => ({
    fileSet: state.spoilers.revealedFiles,
    descriptionSet: state.spoilers.revealedDescriptions,
});

export const ConnectedSpoilingCell = connect(mapCellStateToProps, { revealFile, revealDescription, updateDescriptions, updateFile })(SpoilingCell);

 export default ConnectedSpoilingCell