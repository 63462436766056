import React from 'react';
import Typography from '@mui/material/Typography';



interface PrintNameProps {
    name?: string;
}

export const PrintName: React.FC<PrintNameProps> = ({ name }) => {
    return name
        ? (
            <Typography variant="h5" gutterBottom component="div" sx={{backgroundColor:'cornsilk',textAlign:'center',padding:'.5em',borderRadius:'.25em'}}>
                {name}
            </Typography>
        )
        : (<></>);
};
