import React from 'react';
import { Switch } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import { toggleDescriptions, toggleFilenames } from '../ui/spoilersSlice';

export const FileNameSwitch: React.FC = () => {
  const state = useSelector((state: RootState) => state.spoilers.filenames)
  const dispatch = useDispatch()
  return (
    <>
      <p><Switch checked={!!state} onChange={() => { dispatch(toggleFilenames()) }} /> Show filenames and commits</p>
    </>
  )
};

export const DescriptionSwitch: React.FC = () => {
  const state = useSelector((state: RootState) => state.spoilers.descriptions)
  const dispatch = useDispatch()
  return (
    <>
      <p><Switch checked={!!state} onChange={() => { dispatch(toggleDescriptions()) }} /> Show descriptions</p>
    </>
  )
};
