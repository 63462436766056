import React from 'react';
import Typography from '@mui/material/Typography';
import { FileTable } from './FileTable';
import { ITimeline } from '../../../types/ITimeline';
import { IListedFileProps } from '../../../types/IListOfFiles';
import { IRowStyle } from '../../../types/WorkEntryProps';
import { MediaState } from '../../../app/store';
import { DescriptionSwitch, FileNameSwitch } from '../../Switches';

interface ListFilesProps {
    files: IListedFileProps[];
    type: string;
    noFiles: string;
    autoupdate?: string;
    gridRowStyle: IRowStyle
    media: MediaState;
    timeline?: any;

}
const ListFiles: React.FC<ListFilesProps> = (props) => {
    const { autoupdate, noFiles, ...rest } = props

    if (!autoupdate) return <></>;
    return props.files?.filter((row: IListedFileProps)=>row.type==='md').length ? (
        <>{props.timeline && (
            <>
                <FileNameSwitch />
                <DescriptionSwitch />
            </>
        )}
        <FileTable {...props} />
        </>
    ) : (
        <Typography variant="h6" gutterBottom component="div">
            {noFiles}
        </Typography>
    );
};
interface FileProps {
    timeline?: ITimeline;
    autoupdate?: string;
    gridRowStyle: IRowStyle
    media:MediaState
}
export const UncommittedFiles: React.FC<FileProps> = (props) => {
    const { timeline, ...rest } = props
    const type = 'Modified but uncommitted';
    const noFiles = 'No uncommitted modified files';
    const files = timeline?.uncommitted_files as [];

    return <ListFiles files={files} type={type} noFiles={noFiles} {...props} />;
};
export const CommittedFiles: React.FC<FileProps> = (props) => {
    const { timeline } = props

    const type = 'Committed';
    const noFiles = 'No committed files';
    const files = timeline?.committed_files as [];
    return <ListFiles files={files} type={type} noFiles={noFiles} {...props} />;
};
