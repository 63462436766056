import React from 'react';
import { TableRow, TableCell, TableHead, Paper, Table, TableBody } from '@mui/material';
import { MediaState, RootState } from '../../../../app/store';
import { IListedFileProps } from '../../../../types/IListOfFiles';
import { connect } from 'react-redux';
import { showFilenames, hideFilenames, toggleFilenames, showDescriptions, hideDescriptions, toggleDescriptions } from '../../../../ui/spoilersSlice'
import SpoilingCell from './SpoilingCell';
import { extractDateFromListedFile } from '../../../../functions/extractDateFromListedFile';
import { IRowStyle } from '../../../../types/WorkEntryProps';
import { GridTableRow } from '../../../GridTableRow';
import WbrString from '../../../WbrString';
import TablePaper from '../../../TablePaper';
import moment from 'moment';
import { WordCountCell } from './WordCountCell';

interface CommitRowProps {
    row: IListedFileProps;
    shouldShowFilenames?: number;
    shouldShowDescriptions?: number;
    showFilenames?: any
    showDescriptions?: any
    gridRowStyle: IRowStyle
    media: MediaState
}

export const CommitRow: React.FC<CommitRowProps> = ({ row, shouldShowFilenames, shouldShowDescriptions, showFilenames, showDescriptions, gridRowStyle, media }) => {

    const commitRowParams = {
        gridRowStyle,
        shouldShowFilenames,
        row,
        showFilenames,
        shouldShowDescriptions,
        showDescriptions,
        media
    };
  
    return (
        <>
            {media.isExtraSmallScreen
                // code for table-tiles
                ? (
                <TableRow sx={{ padding: 0, margin: 0 }}>
                    <TableCell colSpan={2} sx={{ padding: 0, margin: 0 }}>
                        
                        <TablePaper>
                            <StandaloneTable {...commitRowParams}/>
                        </TablePaper>
                    </TableCell>
                </TableRow>
                )
                // code for normal rows
                : ( <ResponsiveCommitRow {...commitRowParams}/>)}
        </>
    );
    
};
// Map Redux state to component props
const mapStateToProps = (state: RootState) => ({
    shouldShowFilenames: state.spoilers.filenames,
    shouldShowDescriptions: state.spoilers.descriptions,

});

const mapDispatchToProps = {
    showFilenames,
    hideFilenames,
    toggleFilenames,
    showDescriptions,
    hideDescriptions,
    toggleDescriptions,
};

// Connect the component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(CommitRow);



interface getRowOrRowsProps {
    gridRowStyle: IRowStyle;
    shouldShowFilenames?: number;
    row: IListedFileProps;
    showFilenames: any;
    shouldShowDescriptions?: number;
    showDescriptions: any;
    media: MediaState;
}

interface getStandaloneTableProps {
    gridRowStyle: IRowStyle;
    shouldShowFilenames: number | undefined;
    row: IListedFileProps;
    showFilenames: any;
    shouldShowDescriptions: number | undefined;
    showDescriptions: any;
    media: MediaState;
}

const StandaloneTable: React.FC<getStandaloneTableProps> = (props) => {
    const { gridRowStyle } = props
    const headerStyleForSmall = { 'fontWeight': 'bold', 'textAlign':'center', 'backgroundColor':'cornsilk' };
    return (<Table data-debug-metadata="standalone">
        <TableHead>
            <GridTableRow sx={gridRowStyle.file}>
                <TableCell component="th" scope="col" sx={headerStyleForSmall}>File</TableCell>
                <TableCell sx={headerStyleForSmall}>Change</TableCell>
            </GridTableRow>
        </TableHead>
        <TableBody>
           <ResponsiveCommitRow {...props}/>
        </TableBody>
    </Table>);
}

const ResponsiveCommitRow: React.FC<getStandaloneTableProps> = ({ gridRowStyle, shouldShowFilenames, row, showFilenames, shouldShowDescriptions, showDescriptions, media }: getRowOrRowsProps) => {
    return (<>
        <GridTableRow sx={gridRowStyle.file}>
            <SpoilingCell
                shouldShow={shouldShowFilenames}
                row={row}
                overload={{file: <WbrString text={row.file} splitChars={['_','-']}/>}}
                show={showFilenames}
                component='th'
                scope='row'
                message='The name of the file can be a spoiler!'
                hideButton={true}
                buttonText='Show filename'
                buttonTextRevealAll='Show all filenames'
                type='file' />
            <SpoilingCell
                shouldShow={shouldShowDescriptions}
                row={row}
                show={showDescriptions}
                message='The description of the change can be a spoiler!'
                hideButton={true}
                buttonText='Show description'
                buttonTextRevealAll='Show all descriptions'
                type='description' />
            {/* <TableCell>Wordcount</TableCell> */}

            {!media.isExtraSmallScreen && <>
                <TableCell>{extractDateFromListedFile({ row })}</TableCell>
                <WordCountCell {...{row}}/>
            </>}

        </GridTableRow>

        {media.isExtraSmallScreen && <>
        <GridTableRow sx={gridRowStyle.fileAux}>
            <TableCell colSpan={2}>
                {extractDateFromListedFile({ row }) as unknown as string + (media.isExtraSmallScreen ? ": " + (row.modified ? moment(row.modified).fromNow() : "N/A") : '')}
            </TableCell>
            
        </GridTableRow>

        <GridTableRow sx={gridRowStyle.file}>
        <TableCell>Word count</TableCell>
        <WordCountCell {...{row}}/>
        </GridTableRow>
        
        </>}
    </>);
}


