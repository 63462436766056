import { combineReducers, configureStore } from '@reduxjs/toolkit';
import spoilersSlice from '../ui/spoilersSlice';
import { enableBatching } from 'redux-batched-actions';
import mediaSlice from '../ui/mediaSlice';



export const store = configureStore({
  reducer: {

    spoilers: spoilersSlice,
    media: mediaSlice
  },
  devTools: true // set to `true` if dev mode, i.e. import.meta.env.DEV for vite

});

export type RootState = ReturnType<typeof store.getState>;
export type MediaState = RootState['media'];
