import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { IRowStyle, WorkEntryProps } from '../../types/WorkEntryProps';
import { ITimeline } from '../../types/ITimeline';
import CommitStatus from './OpenedEntry/CommitStatus';
import { MetaTables } from './OpenedEntry/DateTable';
import { WorkEntryParentRow } from './WorkEntryParentRow';
import { MediaState } from '../../app/store';
import { PrintStatus } from './PrintStatus';
import { PrintName } from './PrintName';

export interface WorkEntryPseudoRowProps extends Partial<WorkEntryProps> {
    entryProps: WorkEntryProps;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    timeline?: ITimeline;
    status?: string;
    autoupdate?: string;
    lastUpdated?: string | Date;
    lastWorkedOn?: string | Date;
    media: MediaState;
    groupType: string;
    gridRowStyle: IRowStyle;
}
export const WorkEntryPseudoRow: React.FC<WorkEntryPseudoRowProps> = (props) => {
    const {
        entryProps,
        setOpen,
        open,
        timeline,
        status,
        autoupdate,
        lastUpdated,
        lastWorkedOn,
        media,
        groupType,
        gridRowStyle,
    } = props
    const rows = [
        {
            event: autoupdate ? "Hardcoded last update" : "Last update",
            date: lastUpdated as Date,
        },
        {
            event: autoupdate ? "Hardcoded last change" : "Last change",
            date: lastWorkedOn as Date,
        }
    ]
        .map((row) => {
            return ({ ...row, id: row.event, ...entryProps })
        })

    return (<>
        <WorkEntryParentRow {...{ ...entryProps, setOpen, open, timeline, groupType }} />
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={100}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: '1px' }}>
                        <PrintName name={entryProps.name}/>
                        <PrintStatus status={status} />
                        <CommitStatus {...{ autoupdate, timeline }} />
                        <MetaTables {...{ ...props, rows }} />
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </>);
};
