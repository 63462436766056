import React, { useEffect, useState } from 'react';
import { TableRow, TableCell, useMediaQuery, Table, TableBody } from '@mui/material';

import { WorkEntryProps } from '../../types/WorkEntryProps';
import { ITimeline } from '../../types/ITimeline';
import TablePaper from '../TablePaper';
import { WorkEntryPseudoRow } from './WorkEntryPseudoRow';


const WorkEntry: React.FC<WorkEntryProps> = (entryProps: WorkEntryProps) => {
    const { lastUpdated, lastWorkedOn, status, groupType, autoupdate } = entryProps
    const [open, setOpen] = useState(false);
    const [timeline, updateTimeline] = useState<undefined | ITimeline>();
    const { media, gridRowStyle } = entryProps
    useEffect(() => {
        if (!autoupdate) return
        const fetchData = async () => {
            if (!autoupdate) return
            try {
                const response = await fetch(autoupdate);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const jsonData = await response.json();
                updateTimeline(jsonData)
            } catch (error) {
                console.error('Error fetching data:', error);
                console.error('URL:', autoupdate);
                updateTimeline(undefined)
            }
        };

        fetchData();
        const id = setInterval(fetchData, 30000);
        return () => clearInterval(id);
    }, [updateTimeline, autoupdate]);
    const props = { entryProps, setOpen, open, timeline, status, autoupdate, lastUpdated, lastWorkedOn, media, groupType, gridRowStyle };
    return (
        <React.Fragment>
            {media.isExtraSmallScreen &&
                <TableRow>
                    <TableCell colSpan={100}>
                        <TablePaper>
                            <Table>
                                <TableBody>
                                    <WorkEntryPseudoRow {...props} />
                                </TableBody>
                            </Table>
                        </TablePaper>
                    </TableCell>
                </TableRow>
            }
            {!media.isExtraSmallScreen && <WorkEntryPseudoRow {...props} />}
        </React.Fragment>
    );


}
export default WorkEntry;
