import React from 'react';
import { TableCell, IconButton } from '@mui/material';
import { ITimeline } from '../../types/ITimeline';
import { OpenClosedArrow } from '../OpenClosedArrow';
import { StatusIcon } from './TableCellOnlineStatusIcon';
import { IRowStyle } from '../../types/WorkEntryProps';
import { GridTableRow } from '../GridTableRow';
import { FullWidthColumns } from './FullWidthColumns';
import { MediaState } from '../../app/store';
import { WorkEntryParentRowProps } from './WorkEntryParentRow';

export interface CollapsibleRowProps extends WorkEntryParentRowProps{
    media: MediaState;
    gridRowStyle: IRowStyle;
    cellStyle: any;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    collapseCellStyle: any;
    collapseIconStyle: any;
    autoupdate?: string;
    timeline?: ITimeline;
    link: string;
    name: string;
    typeOfWork?: string;
    lastWorkedOnDetail?: string;
}
export const CollapsibleRow: React.FC<CollapsibleRowProps> = ({ media, gridRowStyle, cellStyle, setOpen, open, collapseCellStyle, collapseIconStyle, autoupdate, timeline: entryData, link, name, typeOfWork, lastWorkedOnDetail }) => {
    return <GridTableRow
        sx={{ '& > *': { borderBottom: 'unset' ,...(open ? {fontWeight:'800'}: {})}, ...(media.isExtraSmallScreen ? gridRowStyle.aux : gridRowStyle.main), ...cellStyle }}
        {...(media.isExtraSmallScreen ? { onClick: () => setOpen(!open) } : {})}
    >
        <TableCell sx={{ ...collapseCellStyle }}>
            <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
                sx={collapseIconStyle}
            >
                <OpenClosedArrow open={open} />
            </IconButton>
        </TableCell>

        {media.isExtraSmallScreen ? (
            <TableCell sx={{ textAlign: 'right' }} component="th" scope="row">
                <StatusIcon sx={{ ...collapseIconStyle }} isUpdatable={Boolean(autoupdate)} isRetrieved={Boolean(entryData)} />
            </TableCell>
        ) : <FullWidthColumns {...{ link, name, autoupdate, entryData, typeOfWork, lastWorkedOnDetail, open }} />}
    </GridTableRow>;
};
