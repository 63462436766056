import React from 'react';
import { Typography, Card, CardContent } from '@mui/material';

export function InvitationBlock() {
  return <Card>
    <CardContent>
      <Typography variant="h5">About Me</Typography>
      <Typography paragraph>
        Hi, I’m @MustardBell. I'm a tech enthusiast and a language lover. I also enjoy creative writing. The primary purpose of this account is to share computer-aided writing scripts and tools that I've developed, making them available for others to use.
      </Typography>
      <Typography paragraph>
        Below are two tables: one with tools I developed (only one so far) and the other with my creative works. Be advised that the filenames and change descriptions may contain spoilers (filenames more so since they reveal the structure).
      </Typography>
      {process.env.REACT_APP_LINK_MIRRORS && <><Typography variant='h6'>Mirrors</Typography><Card><CardContent>{JSON.parse(process.env.REACT_APP_MIRRORS as string).map((mirror: any) => (
        <div key={mirror.NAME}>
          <a href={mirror.LINK}>{mirror.NAME}</a>
        </div>
      ))}</CardContent></Card></>}
    </CardContent>
  </Card>;
}
