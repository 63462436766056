import React, { Suspense, useEffect, useState } from 'react';
import { Typography, Card, CardContent, Stack, useMediaQuery } from '@mui/material';
import { InvitationBlock } from './components/InvitationBlock';
import { ToolTable, WorksTable } from './components/WorkEntry/ListingTables';
import { populate } from './data/works';
import { tools } from './data/tools';
import { DescriptionSwitch, FileNameSwitch } from './components/Switches';
import { enableMapSet } from 'immer';
import { useDispatch, useSelector } from 'react-redux';
import { resetScreen, setExtraSmallScreen, setLargeScreen, setMediumScreen, setMinScreen } from './ui/mediaSlice';
import { IStackStyle, notMinStyle, minStyle } from './style/constants/StackStyles';
import screenSizes from './style/constants/ScreenSize';
import { RootState } from './app/store';
import { WorkEntryProps } from './types/WorkEntryProps';
import './i18n'; 
import LanguageSelector from './components/LanguageSelector';
import { useTranslation } from 'react-i18next';
//process.env.REACT_APP_HOMEPAGE
enableMapSet();
function App() {
  const ns = process.env.REACT_APP_TRANSLATIONS_NAMESPACE || 'common'
  const {t: translate} = useTranslation(ns);
  const isLargeScreen = useMediaQuery(`(min-width:${screenSizes.large.min})`);
  const isMediumScreen = useMediaQuery(`(min-width:${screenSizes.medium.min}) and (max-width:${screenSizes.medium.max})`);
  const isMinScreen = useMediaQuery(`(min-width:${screenSizes.min.min}) and (max-width:${screenSizes.min.max})`);
  const isExtraSmallScreen = useMediaQuery(`(max-width:${screenSizes.extraSmall.max})`);

  const isSmall = useSelector((state: RootState) => state.media.isSmall)

  const [stackStyle, setStackStyle] = useState<IStackStyle>(notMinStyle);

  const dispatch = useDispatch()

  useEffect(() => {
    switch (true) {
      case isLargeScreen:
        dispatch(setLargeScreen());
        break;
      case isMediumScreen:
        dispatch(setMediumScreen());
        break;
      case isMinScreen:
        dispatch(setMinScreen());
        break;
      case isExtraSmallScreen:
        dispatch(setExtraSmallScreen());
        break;
      default:
        dispatch(resetScreen())
        break;
    }

    if (isSmall) {
      setStackStyle(minStyle);
    } else {
      setStackStyle(notMinStyle);
    }
  }, [
    isLargeScreen,
    isMinScreen,
    isExtraSmallScreen,
    isMediumScreen,
    isSmall,
    setLargeScreen,
    setMediumScreen,
    setMinScreen,
    setExtraSmallScreen,
    resetScreen
  ]);

  return (
    <Suspense fallback={<div>Loading translations...</div>}>

    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={3}
      sx={{ ...stackStyle, margin: 0 }}
    >
      <Typography variant="h2" gutterBottom sx={(isSmall ? { textAlign: 'center' } : {})}>
        Fic&shy;tion Writer & Tool De&shy;veloper
        <LanguageSelector>
          <br/>
        </LanguageSelector>
      </Typography>
      <InvitationBlock />
      <Card>
        <CardContent>
          <FileNameSwitch />
          <DescriptionSwitch />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography variant="h5">{translate('App.MyTools')}</Typography>
          <ToolTable populate={tools as WorkEntryProps[]} />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography variant="h5">{translate('App.MyWorks')}</Typography>
          <WorksTable populate={populate as WorkEntryProps[]} />
        </CardContent>
      </Card>

    </Stack>
    </Suspense>
  );
}

export default App;




