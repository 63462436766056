import React from 'react';
import {
  TableHead,
  TableCell
} from '@mui/material';
import { IRowStyle } from '../types/WorkEntryProps';
import { GridTableRow } from './GridTableRow';
import { AbstractTableParameters } from './AbstractTable';

interface AbstractTableHeadProps extends AbstractTableParameters {
  gridRowStyle: IRowStyle;
  changeDescription: JSX.Element | string;
}
export const AbstractTableHead: React.FC<AbstractTableHeadProps> = ({ gridRowStyle, tableType, groupType, changeDescription }) => {

  return (<TableHead>
    <GridTableRow sx={gridRowStyle.main}>
      <TableCell />
      <TableCell>{tableType}</TableCell>
      <TableCell />
      <TableCell align="center">{groupType}</TableCell>
      <TableCell align="right">{changeDescription}</TableCell>
    </GridTableRow>
  </TableHead>);
};
