import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

/**
 *
 * @param {{open:boolean}} {open} Does arrow show open or closed state?
 * @returns Up arrow if open is true, down arrow if open is false
 */
export const OpenClosedArrow: React.FC<{
    open: boolean;
}> = ({ open }: { open: boolean; }) => {
    return open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
};
