import { WorkEntryProps } from '../types/WorkEntryProps';
import { getDateObject } from './getDateObject';

export const processWorkEntryDates = (node: WorkEntryProps): WorkEntryProps => {
  if (!node.name) throw new Error('Work has no name');

  const workEntryData: WorkEntryProps = { ...node };
  if (node.lastUpdated) {
    workEntryData.lastUpdated = getDateObject(node.lastUpdated);
  }
  if (node.lastWorkedOn) {
    workEntryData.lastWorkedOn = getDateObject(node.lastWorkedOn);
  }

  return workEntryData;
};
