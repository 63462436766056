export interface IStackStyle {
  position?: string;
  width: string;
  left?: string;
  right?: string;
}
export const notMinStyle: IStackStyle = {
  position: "absolute",
  width: 'calc(max(50vw,684px))',
  left: 'calc((100vw - max(684px,50vw)) / 2)',
  right: 'calc((100vw - max(684px,50vw)) / 2)'
};
export const minStyle: IStackStyle = {
  position: undefined,
  width: '100vw',
  left: undefined,
  right: undefined
};
