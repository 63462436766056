import React from 'react';

interface WbrStringProps {
  text: string;
  splitChars: string[];
}

const WbrString: React.FC<WbrStringProps> = ({ text, splitChars }) => {
  // Create a regular expression to match any of the split characters
  const splitRegex = new RegExp(`([${splitChars.join('')}])`, 'g');

  // Split the text using the regular expression and insert <wbr/> tags
  const parts = text.split(splitRegex).map((part, index) => {
    if (splitChars.includes(part)) {
      return (
        <React.Fragment key={`split-${index}`}>
          {part}
          <wbr />
        </React.Fragment>
      );
    }
    return <span key={`part-${index}`}>{part}</span>;
  });

  return <>{parts}</>;
};

export default WbrString;
