import { Paper } from "@mui/material";
import React, { ReactNode } from "react";

const TablePaper: React.FC<{ children: ReactNode | ReactNode[] }> = ({ children }) => {
    return (
        <Paper sx={{ marginLeft: 0, marginRight: 0, marginTop: '1em', width: '100%' }} elevation={3}>
            {children}
        </Paper>
    )
}

export default TablePaper