import React from 'react';
import { Link, TableCell } from '@mui/material';
import { ITimeline } from '../../types/ITimeline';
import { StatusIcon } from './TableCellOnlineStatusIcon';

interface FullWidthColumnsProps {
    link?: string;
    name?: string;
    autoupdate: string | undefined;
    entryData: ITimeline | undefined;
    typeOfWork: string | undefined;
    lastWorkedOnDetail: string | undefined;
    open?: boolean
}
export const FullWidthColumns: React.FC<FullWidthColumnsProps> = ({ link, name, autoupdate, entryData, typeOfWork, lastWorkedOnDetail, open }) => {
    return (
        <>
            <TableCell component="th" scope="row">
                <Link href={link}>{name}</Link>
            </TableCell>
            <TableCell>
                <StatusIcon isUpdatable={Boolean(autoupdate)} isRetrieved={Boolean(entryData)} />
            </TableCell>
            <TableCell align="center">{typeOfWork ?? ''}</TableCell>
            <TableCell align="right">{lastWorkedOnDetail ?? ''}</TableCell>
        </>
    );
};
