// i18n.ts
import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';

const shouldUseTranslations = process.env.REACT_APP_USE_TRANSLATIONS !== '0' && (process.env.REACT_APP_USE_TRANSLATIONS as unknown as number) !== 0;
const selectedLanguage = shouldUseTranslations ? localStorage.getItem('selectedLanguage') ?? undefined : 'en';

const i18nInstance = shouldUseTranslations ? i18n : i18n.use(LanguageDetector);

const initOptions: InitOptions = {
  fallbackLng: 'en',
  ...(shouldUseTranslations ? { lng: selectedLanguage } : {}),
  debug: true,
  interpolation: {
    escapeValue: false,
  },
  backend: {
    backends: [
      HttpBackend,
      resourcesToBackend((lng: string, ns: string) => {
        if (!shouldUseTranslations) {
          return import(`./locales/${ns}/en.json`);
        }
        return import(`./locales/${ns}/${lng}.json`);
      })
    ],
    backendOptions: [{
      loadPath: 'https://fictiontools.com/locales/{{ns}}/{{lng}}.json'
    }]
  },
  ns: ['common', 'git', 'main'],
  defaultNS: 'common',
  fallbackNS: 'common'

};

i18nInstance
  .use(LanguageDetector)
  .use(ChainedBackend)
  .use(initReactI18next)
  .init(initOptions);

export default i18nInstance;
