import React from 'react';
import { TableCell } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import { IRowStyle } from '../../../types/WorkEntryProps';
import { GridTableRow } from '../../GridTableRow';
import { MediaState } from '../../../app/store';

interface IL {
    gridRowStyle: IRowStyle;
    media: MediaState;
}
export const LargeCommitTableHead: React.FC<IL> = ({ media, gridRowStyle }) => {
    return (
        <>
            {!media.isExtraSmallScreen && <TableHead>
                <GridTableRow sx={gridRowStyle.file}>
                    <TableCell component="th" scope="col">File</TableCell>
                    <TableCell>Change</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Word count</TableCell>

                </GridTableRow>
            </TableHead>}
        </>
    );
};
