  
  export interface ScreenSize {
    min?: string;
    max?: string;
  }
  
  export interface Screen {
    large: ScreenSize;
    medium: ScreenSize;
    min: ScreenSize;
    extraSmall: ScreenSize;
  }
  
  const screenSizes: Screen = {
    large: { min: '800px' },
    medium: { min: '686px', max: '799px' },
    min: { min: '516px', max: '685px' },
    extraSmall: { max: '515px' }
  };

  export default screenSizes