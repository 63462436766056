import React from 'react';
import { TableCell, Tooltip } from '@mui/material';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import BlockIcon from '@mui/icons-material/Block';

interface TableCellOnlineStatusIconProps {
    isUpdatable?: boolean | 1 | 0;
    isRetrieved?: boolean | 1 | 0;
}
export const TableCellOnlineStatusIcon: React.FC<TableCellOnlineStatusIconProps> = ({ isUpdatable, isRetrieved }) => {
    return (
        <TableCell>
            <StatusIcon isUpdatable={Boolean(isUpdatable)} isRetrieved={Boolean(isRetrieved)} />
        </TableCell>
    );
};

interface StatusIconProps extends TableCellOnlineStatusIconProps{
    sx?: object
}

export const StatusIcon: React.FC<StatusIconProps> = ({ isUpdatable, isRetrieved, sx }) => {
    return !isUpdatable ? (
        <Tooltip title="Not updatable">
            <BlockIcon sx={{ color: '#8b8b8b', ...sx }} />
        </Tooltip>
    ) : !isRetrieved ? (
        <Tooltip title="Offline">
            <WifiOffIcon sx={{ color: '#ff3737', ...sx }} />
        </Tooltip>
    ) : (
        <Tooltip title="Live">
            <WifiIcon sx={{ color: '#2ed12e', ...sx }} />
        </Tooltip>
    );
}

