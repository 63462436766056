import { createSlice } from '@reduxjs/toolkit';

type screenSizeString = undefined | 'extraSmall' | 'min' | 'medium' | 'large';

export const mediaSlice = createSlice({
    name: 'media',
    initialState: {
        isMinScreen: false,
        isExtraSmallScreen: false,
        isMediumScreen: false,
        isLargeScreen: false,
        size: undefined as screenSizeString,
        isSmall: false
    },
    reducers: {
        setMinScreen: (state) => {
            state.isMinScreen = true;
            state.isExtraSmallScreen = false;
            state.isMediumScreen = false;
            state.isLargeScreen = false;
            state.size = 'min'

            state.isSmall = true
        },
        setExtraSmallScreen: (state) => {
            state.isMinScreen = false;
            state.isExtraSmallScreen = true;
            state.isMediumScreen = false;
            state.isLargeScreen = false;
            state.size = 'extraSmall'

            state.isSmall = true

        },
        setMediumScreen: (state) => {
            state.isMinScreen = false;
            state.isExtraSmallScreen = false;
            state.isMediumScreen = true;
            state.isLargeScreen = false;
            state.size = 'medium'

            state.isSmall = false


        },
        setLargeScreen: (state) => {
            state.isMinScreen = false;
            state.isExtraSmallScreen = false;
            state.isMediumScreen = false;
            state.isLargeScreen = true;
            state.size = 'large'

            state.isSmall = false


        },
        resetScreen: (state) => {
            state.isMinScreen = false;
            state.isExtraSmallScreen = false;
            state.isMediumScreen = false;
            state.isLargeScreen = false;
            state.size = undefined

            state.isSmall = false


        },
    },
});

export const { setMinScreen, setExtraSmallScreen, setMediumScreen, setLargeScreen, resetScreen } = mediaSlice.actions;

export default mediaSlice.reducer;

