import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import { extractTimeZone } from '../../../functions/extractTimeZone';
import { DescriptionSwitch, FileNameSwitch } from '../../Switches';
import { ITimeline } from '../../../types/ITimeline';
import { DateRow } from '../../../types/DateRow';
import { CommittedFiles, UncommittedFiles } from './FileLister';
import { MediaState } from '../../../app/store';
import { IRowStyle, WorkEntryProps } from '../../../types/WorkEntryProps';
import { GridTableRow } from '../../GridTableRow';
import TablePaper from '../../TablePaper';
import { WorkEntryPseudoRowProps } from '../WorkEntryPseudoRow';

export interface MetaTablesProps extends Partial<WorkEntryPseudoRowProps>{
    rows: DateRow[];
    timeline?: ITimeline;
    autoupdate?: string;
    media: MediaState;
    gridRowStyle: IRowStyle
}
export const MetaTables: React.FC<MetaTablesProps> = ({ rows, timeline, autoupdate, media, gridRowStyle }) => {

    return (
        <>


            <Table size="small" aria-label="dates" sx={{ width: 'calc(min(100vw - 40px,100%))' }}>
                <TableHead>
                    {!media.isExtraSmallScreen && <GridTableRow sx={gridRowStyle.date}>
                        <TableCell />
                        <TableCell>When</TableCell>
                        <TableCell>Date</TableCell>
                    </GridTableRow>}
                </TableHead>
                <TableBody>
                    {rows.map((row) => {
                        if (!row.id) throw new Error('Empty id')
                        return (
                            <DateTableRow {...row} key={`${row.id}`} media={media} gridRowStyle={gridRowStyle} />
                        );
                    })}
                </TableBody>
            </Table>
            <CommittedFiles timeline={timeline} autoupdate={autoupdate} gridRowStyle={gridRowStyle} media={media} />
            <UncommittedFiles timeline={timeline} autoupdate={autoupdate} gridRowStyle={gridRowStyle} media={media} />
        </>
    );
};
const DateTableRow: React.FC<DateRow> = ({ event, date, media, gridRowStyle }) => {

    return (
        <>
            <>
                {media.isExtraSmallScreen ? <TableRow sx={{ padding: 0, margin: 0 }}><TableCell colSpan={5} sx={{ padding: 0, margin: 0 }}>
                    <TablePaper>
                        <Table>
                           
                            <TableBody>
                            {getRowOrRows({ gridRowStyle, event, date, media })}
                            </TableBody>
                        </Table>
                    </TablePaper>
                </TableCell></TableRow> : <>{getRowOrRows({ gridRowStyle, event, date, media })}</>}

            </>
            

        </>
    );
};
interface getRowOrRowsProps {
    gridRowStyle: IRowStyle;
    event: string | undefined;
    date: Date | undefined;
    media: MediaState
}

function getRowOrRows({ gridRowStyle, event, date, media }: getRowOrRowsProps) {
    return <>
        <GridTableRow sx={gridRowStyle.date}>
            <TableCell component="th" scope="row">{event ?? ""}</TableCell>
            <TableCell>{date ? moment(date).fromNow() : "N/A"}</TableCell>
            {!media.isExtraSmallScreen && <>
                <TableCell>
                    {getLongDate(date)}
                </TableCell>
            </>}


        </GridTableRow>
        {media.isExtraSmallScreen && <GridTableRow sx={{ ...gridRowStyle.auxDate, borderBottom: '3px solid rgba(0, 0, 0, 0.25)' }}>
            <TableCell colSpan={2}>
                {getLongDate(date)}
            </TableCell>
        </GridTableRow>}
    </>;
}

function getLongDate(date: Date | undefined): React.ReactNode {
    return date ? moment(date).format("YYYY MMMM DD [at] HH:mm:ss, ") + extractTimeZone(date) : "N/A";
}

