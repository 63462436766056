import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/material/styles';
import Flag, { LanguageCode } from './Flag'; 
// Define a styled Select component with custom width
const StyledSelect = styled(Select)(({ theme }) => ({
  minWidth: 120,
}));

interface LanguageSelectorProps {
    children?: JSX.Element;
}

const languages: Array<[LanguageCode, string, string?]> = [
  ['en','English', '🇬🇧'],
  ['uk','Ukrainian', '🇺🇦'],
  ['eo','Esperanto'],
  ['ru','Russian'],
]
const renderMenuItems = () => {
  const items = [];
  for (const [code, label, flag] of languages) {
    items.push(
      <MenuItem key={code} value={code}>
        {!flag ? <><Flag code={code} /> {label}</> : <>{flag} {label}</>}
      </MenuItem>
    );
  }
  return items;
};
const LanguageSelector: React.FC<LanguageSelectorProps> = ({children}) => {
  const { i18n } = useTranslation();
  if (!process.env.REACT_APP_USE_TRANSLATIONS) return <></>;
  if (process.env.REACT_APP_REMOVE_DEV) return <></>;

  const changeLanguage = (event: any) => {
    const lng = event.target.value;
    i18n.changeLanguage(lng);
    localStorage.setItem('selectedLanguage', lng); // Save selected language
  };

  return (
    <>
    {children}
    <StyledSelect
      value={i18n.language}
      onChange={changeLanguage}
      displayEmpty
    >
      {renderMenuItems()}
      
      {/* Add MenuItems for other languages with their corresponding emojis */}
    </StyledSelect>
    </>
  );
};

export default LanguageSelector;
