import React, { ReactNode } from 'react';
import { AbstractTable } from '../AbstractTable';
import { WorkEntryProps } from '../../types/WorkEntryProps';
import { TableRowProps } from '@mui/material';


const ToolTable: React.FC<{ populate?: Array<WorkEntryProps>, children?: TableRowProps | Array<TableRowProps> }> = ({ populate, children }) => {
  return (
    <AbstractTable tableType='Tool' groupType='Type' populate={populate}>
      {children && children}
    </AbstractTable>
  )
}

const WorksTable: React.FC<{ populate?: Array<WorkEntryProps>, children?: TableRowProps | Array<TableRowProps> }> = ({ populate, children }) => {
  return (
    <AbstractTable tableType='Work' groupType='Fan&shy;dom' populate={populate}>
      {children && children}
    </AbstractTable>

  )
}


export { ToolTable, WorksTable };