import React from 'react';
import { RootState } from '../../../app/store';
import { connect } from 'react-redux';

interface CommitStatusProps {
    autoupdate?: string;
    timeline?: {
        commitInfo?: string;
        commitAgo?: string;
    };
    shouldShowFilenames?: number;
}
export const CommitStatus: React.FC<CommitStatusProps> = ({ autoupdate, timeline, shouldShowFilenames }) => {
    if (!autoupdate) return (<></>);
    console.log('fgg')
    

    return (
        <>
            {timeline?.commitInfo ? (
                <>
                    Last commit: {shouldShowFilenames
                        ? <>{timeline?.commitInfo}</>
                        : <em>Commit text hidden</em>}
                    {timeline.commitAgo ? `, ${timeline.commitAgo}` : ''}
                </>
            ) : (
                <>Live status unretrieved. The hardcoded timestamps are older than actual changes</>
            )}
        </>
    );
};

// Map Redux state to component props
const mapStateToProps = (state: RootState) => ({
    shouldShowFilenames: state.spoilers.filenames,

});

// Connect the component to the Redux store
export default connect(mapStateToProps)(CommitStatus);
