import React, { useEffect, useMemo, useState } from 'react';
import { TableCell, Typography } from '@mui/material';
import { IListedFileProps } from '../../../../types/IListOfFiles';

export const WordCountCell: React.FC<{ row: IListedFileProps; }> = ({ row }) => {
    const [goal, setGoal] = useState(false);
    const [color, setColor] = useState<string>('');

    const goalDiff = useMemo(() => Number(row.wordCount) - Number(row.setGoal), [row.wordCount, row.setGoal]);
    const goalPercent = useMemo(() => (Number(row.wordCount) / Number(row.setGoal)) * 100, [row.wordCount, row.setGoal]);

    useEffect(() => {
        const determineGoalState = (): string => {
            if (goal) return '';
            if (goalPercent < 25) {
                return "start";
            } else if (goalPercent >= 25 && goalPercent < 45) {
                return "progress";
            } else if (goalPercent >= 45 && goalPercent < 70) {
                return "finalizing";
            } else if (goalPercent >= 70 && goalPercent < 80) {
                return "almost";
            } else if (goalPercent >= 80 && goalDiff >= -200 && goalPercent < 120) {
                if (goalDiff >= -200 || goalPercent >= 95) { return "done"; }
                return "almost";
            } else if (goalPercent >= 120 && goalDiff >= 500) {
                return "overshot";
            } else {
                return ""; // Default fallback state if none above
            }
        };

        const goalState = determineGoalState();
        switch (goalState) {
            case "start":
                setColor('darksalmon');
                break;
            case "progress":
                setColor('burlywood');
                break;
            case "finalizing":
                setColor('lightseagreen');
                break;
            case "almost":
                setColor('lightgreen');
                break;
            case "done":
                setColor('lime');
                break;
            case "overshot":
                setColor('thistle');
                break;
            default:
                setColor('');
        }
    }, [goalPercent, goalDiff, goal]); // Add dependencies here

    const handleClick = () => setGoal(!goal);

    return (
        <TableCell onClick={handleClick} {...color ? { sx: { 'backgroundColor': color } } : {}}>
            {row.setGoal ? (
                <Typography sx={{ 'fontWeight': 'bold', 'cursor': 'pointer' }}>
                    {goal ? `Goal: ${row.setGoal}` : row.wordCount}
                </Typography>
            ) : (
                row.wordCount
            )}
        </TableCell>
    );
};
