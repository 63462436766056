import React, { ReactNode } from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  Paper,
  TableRowProps,
} from '@mui/material';
import WorkEntry from './WorkEntry/WorkEntry';
import { IRowStyle, WorkEntryProps } from '../types/WorkEntryProps';
import { useSelector } from 'react-redux';
import { MediaState, RootState } from '../app/store';
import { processWorkEntryDates } from '../functions/processWorkEntryDates';
import { getGridTemplateColumns } from '../functions/getTemplateColumns';
import { AbstractTableHead } from './TinyAbstractTableHead';

export interface AbstractTableParameters {
  tableType: string;
  groupType: string;
  children?: TableRowProps | Array<TableRowProps>;
  populate?: Array<WorkEntryProps>;
}

export const AbstractTable: React.FC<AbstractTableParameters> = (props) => {
  const {
    children,
    populate,
  } = props;
  const media: MediaState = useSelector((state: RootState) => state.media);
  const gridRowStyle: IRowStyle = getGridTemplateColumns(media);
  const changeDescription: ReactNode | string = <>What&nbsp;I&nbsp;was working&nbsp;on</>;

  const abstractProps = { ...props, gridRowStyle, changeDescription }
  const entryProps = { ...props, gridRowStyle, media }
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        {!media.isExtraSmallScreen && <AbstractTableHead {...abstractProps} />}
        <TableBody>
          <>
            {populate?.map((node: WorkEntryProps) => (
              <WorkEntry key={node.name} {...entryProps} {...processWorkEntryDates(node)} />
            ))}
            {children}
          </>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
