import { WorkProps } from '../types/WorkEntryProps';

export const tools: Array<WorkProps> = [
  {
    link: "https://mustardbell.github.io/manusrkript-worm-wordsmith/",
    name: "Manuskript Worm Wordsmith",
    lastUpdated: "2024-03-07T21:37:29+0200",
    lastWorkedOn: "2024-03-07T21:37:29+0200",
    lastWorkedOnDetail: 'Updated links',
    typeOfWork: 'Online Tool'
  }
];
