import React from 'react';
import { ITimeline } from '../../types/ITimeline';
import { WorkEntryProps } from '../../types/WorkEntryProps';
import { CollapsibleRow } from './CollapsibleRow';
import { MiniScreenRows } from './MiniScreenRows';

export interface WorkEntryParentRowProps extends WorkEntryProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    timeline?: ITimeline;
}

export const WorkEntryParentRow: React.FC<WorkEntryParentRowProps> = (props) => {
    const { media } = props
    const {tableType, groupType, ...collapsibleProps} = props
    const collapseCellStyle = media.isSmall ? { paddingLeft: 0, paddingRight: 0 } : {};
    const collapseIconStyle = media.isSmall ? { paddingLeft: '2px', paddingRight: '2px' } : {};
    const newLocal = media.isExtraSmallScreen
        ? { '& > td, & > th': { paddingLeft: '3px', paddingRight: '3px' } }
        : {};
    const cellStyle = media.isMinScreen
        ? { '& > td, & > th': { paddingLeft: '6px', paddingRight: '6px' } }
        : newLocal;

    return (
        <>
            {media.isExtraSmallScreen &&  <MiniScreenRows {...props}/>}
            <CollapsibleRow {...{ ...props, cellStyle, collapseCellStyle, collapseIconStyle }}/>
        </>
    );
};
