import { WorkProps } from '../types/WorkEntryProps';

export const populate: Array<WorkProps> = [
  {
    link: 'https://forums.spacebattles.com/threads/the-fox-of-earth-bet-oc-insert-cyoa-worm-supergirl-crossover.1142890/',
    name: 'The Fox of Earth Bet',
    lastUpdated: '2024-05-19T22:56:35+0300',
    lastWorkedOn: '2024-05-20T11:10:52+0300',
    lastWorkedOnDetail: 'Chapter 6',
    typeOfWork: 'Worm x Supergirl x Original',
    status: 'Actively working on it',
    autoupdate: 'https://fictiontools.com/api/status/1'
  },
  {
    link: 'https://forums.spacebattles.com/threads/mr-mayor-fsn-mcu-au.1164852/',
    name: 'Mr. Mayor!',
    lastUpdated: '2024-05-22T11:31:06+0300',
    lastWorkedOn: '2024-05-23T12:10:21+0300',
    lastWorkedOnDetail: 'Chapter 1',
    typeOfWork: 'FSN x MCU AU',
    status: 'Actively working on it',
    autoupdate: 'https://fictiontools.com/api/status/2'
  },
  {
    link: 'https://forums.spacebattles.com/threads/humans-shall-not-observe-magic.1166565/',
    name: 'Humans shall not observe magic',
    lastUpdated: '2023-07-01T06:02:10+0300',
    lastWorkedOn: '2024-05-19T23:48:34+0300',
    lastWorkedOnDetail: 'Meta: Hrurgraḩ (the Graffr language)',
    typeOfWork: 'Original',
    status: 'Working on it every few months',
    autoupdate: 'https://fictiontools.com/api/status/3'
  }, {
    link: 'https://www.reddit.com/r/HFY/comments/14p5e1o/the_inked_oneshot/',
    name: 'The Inked',
    lastUpdated: '2023-07-03T04:37:49.126+0300',
    lastWorkedOn: '2023-07-03T04:37:49.126+0300',
    lastWorkedOnDetail: 'Posted',
    typeOfWork: 'Original One-Shot',
    status: 'Complete'
  },
];
